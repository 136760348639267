import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const fetchClaims = (token) => {
  let claims;
  try {
    claims = jwtDecode(token);
  } catch (error) {	   
    return null;
  }

  return claims;
};

export const setAccessTokenCookie = (accessToken, handler) => {
  const claims = fetchClaims(accessToken);
  if (claims) {
  	Cookies.set('accessToken', accessToken, {
  	  expires: new Date(claims.exp * 1000),
  	  path: '/'
    });

    Cookies.set('userMeta', handler, {
      expires: new Date(claims.exp * 1000),
      path: '/'
    });
  }   
};

export const removeAuthTokens = () => {
  Cookies.remove('accessToken');
  Cookies.remove('userMeta'); 
};

export const getUser = () => {
  const token = Cookies.get('accessToken') || '';
  return token ? fetchClaims(token) : null;
};

export const getUserMeta = () => {
  return Cookies.get('userMeta') || '';  
};

const getAccessToken = () => (Cookies.get('accessToken'));
export default getAccessToken;
