import { getUser, getUserMeta, setAccessTokenCookie, removeAuthTokens } from '../utils/cookie';

const initialState = {
  user: getUser(),
  userHandler: getUserMeta(),
  isLoginModalOpen: false,
  login: {
    isPending: false,
    token: '',
    data: null,
    error: ''
  },
  registerHandler: {
    isPending: false,   
    data: null,
    error: ''
  }
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_LOGIN_MODAL":
      return {       
        ...state,
        isLoginModalOpen: action.isLoginModalOpen 
      }

    case "USER_LOGIN":
      if (!action.value.error) {
        setAccessTokenCookie(action.token, action.value.data.handler);
      }

      return {       
      	...state,
        user: !action.value.error ? getUser() : null,
        userHandler: !action.value.error ? getUserMeta() : null,
        isLoginModalOpen: !action.value.error ? false : state.isLoginModalOpen,
        login: {
          token: action.token,
          data: action.value.data,
          error: action.value.error
        }      
      }

    case "REGISTER_USER_HANDLER":
      if (!action.value.error) {
        setAccessTokenCookie(action.token, action.value.data.handler);
      }

      return {       
        ...state,
        user: !action.value.error ? getUser() : null,
        userHandler: !action.value.error ? getUserMeta() : null,
        isLoginModalOpen: !action.value.error ? false : state.isLoginModalOpen,
        login: {
          ...state.login,         
          data: action.value.data,
          error: action.value.error ? state.login.error : ""
        },      
        registerHandler: {         
          data: action.value.data,
          error: action.value.error
        }      
      }

    case "USER_LOGOUT":
      removeAuthTokens();

      return {       
        ...initialState,
        user: null            
      }
 
    default:
      return state
  }
}