const jsonObj = {
    "usa": [       
        {
            uniqueTag: "democratic-party",           
            name: "Democratic Party"
        },
        {
            uniqueTag: "republican-party",         
            name: "Republican Party"
        },
        {
            uniqueTag: "general",         
            name: "General"
        }
    ],
    "ind": [
        {
            uniqueTag: "bharatiya-janata-party",        
            name: "Bharatiya Janata Party"
        },
        {
            uniqueTag: "indian-national-congress",          
            name: "Indian National Congress"
        },
        {
            uniqueTag: "general",         
            name: "General"
        }
        // {
        //     uniqueTag: "telugu-desam-party",          
        //     name: "Telugu Desam Party(TDP)"
        // },
        // {
        //     uniqueTag: "ysr-congress-party",          
        //     name: "YSR Congress Party"
        // }
    ],
    // "chn": [
    //     {
    //         uniqueTag: "chinese-communist-party",         
    //         name: "Chinese Communist Party"
    //     }
    // ],
    "gbr": [
        {
            uniqueTag: "conservative-party",         
            name: "Conservative & Unionist Party"
        },
        {
            uniqueTag: "labour-party",        
            name: "Labour Party"
        },
        {
            uniqueTag: "general",         
            name: "General"
        }
    ],
    "eur": [
        {
            uniqueTag: "general",         
            name: "General"
        }
    ],   
    "deu": [
        {
            uniqueTag: "social-democratic-party",         
            name: "Social Democratic Party"
        },
        {
            uniqueTag: "christian-democratic-union",         
            name: "Christian Democratic Union"
        }
    ],
    "fra": [
        {
            uniqueTag: "la-republique-en-marche",         
            name: "La République En Marche"
        },
        {
            uniqueTag: "the-republicans",         
            name: "The Republicans"
        }
       
    ],
    "can": [
        {
            uniqueTag: "liberal-party",         
            name: "Liberal Party"
        },
        {
            uniqueTag: "conservative-party",         
            name: "Conservative Party"
        }
    ],
    // "jpn": [
    //     {
    //         uniqueTag: "liberal-democratic-party",         
    //         name: "Liberal Democratic Party"
    //     },
    //     {
    //         uniqueTag: "constitutional-democratic-party",         
    //         name: "Constitutional Democratic Party"
    //     }       
    // ],
    "nld": [
        {
            uniqueTag: "peoples-party-for-freedom-and-democracy",         
            name: "People's Party for Freedom and Democracy"
        },     
        {
            uniqueTag: "democrats-66",        
            name: "Democrats 66"
        }
    ],
    // "ven": [
    //     {
    //         uniqueTag: "united-socialist-party-of-venezuela",         
    //         name: "United Socialist Party of Venezuela"
    //     },     
    //     {
    //         uniqueTag: "democratic-action",        
    //         name: "Democratic Action"
    //     }       
    // ],
    "aus": [
        {
            uniqueTag: "australian-labor-party",          
            name: "Australian Labor Party"
        },
        {
            uniqueTag: "liberal-party-of-australia",          
            name: "Liberal Party of Australia"
        },
        {
            uniqueTag: "national-party-of-australia",          
            name: "National Party of Australia"
        }
    ],
    // "arg": [
    //     {
    //         uniqueTag: "frente-de-todos",          
    //         name: "Frente de Todos"
    //     },
    //     {
    //         uniqueTag: "juntos-por-el-Cambio",          
    //         name: "Juntos por el Cambio"
    //     }       
    // ],
    // "bra": [
    //     {
    //         uniqueTag: "brazilian-democratic-movement",          
    //         name: "Brazilian Democratic Movement"
    //     },
    //     {
    //         uniqueTag: "Workers-party",          
    //         name: "Workers' Party"
    //     }       
    // ],
    // "tur": [
    //     {
    //         uniqueTag: "justice-and-development-party",          
    //         name: "Justice and Development Party"
    //     },
    //     {
    //         uniqueTag: "republican-peoples-party",          
    //         name: "Republican People's Party"
    //     }       
    // ]
};

export const getName = (country, uniqueTag) => {
    const obj = jsonObj[country].find((p) => p.uniqueTag === uniqueTag);
    return  obj ? obj.name : "";    
};

export default jsonObj; 
