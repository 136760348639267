import './style.scss';

function App() {
  return (
    <footer className="pl-footer">
      <div className="pl-footer__inner">
        <div className="pl-footer__left">         
          <p className="pl-footer__left-title">
            &copy; 2023 Political Ledger
          </p>
        </div>
        <div className="pl-footer__right">
          <ul className="list">
            <li>            
            </li>           
          </ul>
        </div>
      </div>  
    </footer>
  );
}

export default App;
