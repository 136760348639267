const initialState = {
  list: {
    isPending: false,    
    data: [],
    error: ''
  }
}

export default function comment(state = initialState, action) {
  switch (action.type) {
    case "LIST_COMMENTS":    

      return {       
        ...state,
        list: {       
          data: !action.value.error ? action.value.data : [],
          error: action.value.error
        }      
      }

    default:
      return state
  }
}