import './style.scss';
import React, { useState } from 'react';

function App({ addCommentHandler }) {
  const [inputValue, setHandlerInput] = useState("");

  function addComment(e) {
    e.stopPropagation();  
    if (!inputValue) {
      return;
    }
   
    addCommentHandler(inputValue, () => {
      setHandlerInput("");
    });
  }

  return (
    <div className="pl-writecomments"> 
      <div className="pl-writecomments__addcomment">        
        <input 
          type="text"
          value={inputValue}
          placeholder="Write a comment"
          onChange={(e) => setHandlerInput(e.target.value)}
          onKeyPress={(e) => e.code === 'Enter' && addComment(e)}
        />
        <button onClick={addComment} >
          Add
        </button>        
      </div>  
    </div>
  );
}

export default App;
