import './style.scss';
import Modal from 'react-modal';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { toggleLedgerModal, registerIncident } from '../../actions/incident';
import isURL from 'validator/lib/isURL';

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: "rgb(235 235 235 / 75%)"
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const TITLE_MAX_CHAR = 70;
const DESC_MAX_CHAR = 1000;

const FORM = {
  hasErrors: false,
  formCompleted: false,
  value: {     
    title: "",
    desc: "",
    refLink: ""
  },
  error: {     
    title: "",
    desc: "",
    refLink: ""
  }
};

Modal.setAppElement('#root');

function App({ closeModal, styles }) {
  const { country, ledgerTag } = useParams();
  const dispatch = useDispatch();
  const isLedgerModalOpen = useSelector(state => state.incident.isLedgerModalOpen);

  const [form, updateInput] = useState({ ...FORM });

  function updateInputHandler(e, field) {
    const emptyFields = Object.keys(form.value).reduce((list, f) => {
      if (f === field && f !== 'refLink' && !e.target.value) {
        list.push(f);
      }
     
      if (!form.value[f] && f !== 'refLink' && !(f === field && e.target.value)) {       
        list.push(f);
      }

      return list;              
    }, []);

    let err = "";
    if (field === 'title' && e.target.value.length > TITLE_MAX_CHAR) {
      err = `max ${TITLE_MAX_CHAR} chars`
    }

    if (field === 'desc' && e.target.value.length > DESC_MAX_CHAR) {
      err = `max ${DESC_MAX_CHAR} chars`
    }

    if (field === 'refLink' && e.target.value && !isURL(e.target.value)) {
      err = "invalid url"
    }    

    updateInput({
      ...form,
      hasErrors: !!err,
      formCompleted: !emptyFields.length,
      value: {
        ...form.value,
        [field]: e.target.value
      },
      error: {
        ...form.error,
        [field]: err
      }
    });
  }

  function registerIncidentHandler() {
    dispatch(registerIncident({
      ...form.value,
      country: country,
      ledgerTag: ledgerTag      
    })).then((resp) => {
      if (!resp.error) {
        updateInput({ ...FORM });
      }
    });
  }
 
  return (      
    <Modal
      isOpen={isLedgerModalOpen}     
      style={customStyles}      
    >     
      <div className="pl-modal" style={{...styles}}>       
        <div className="pl-modal__header">
          <div className="header-incident">
            Add any <span>incident</span> or <span>offense</span> for this ledger.
          </div>
        </div>
        <div className="pl-modal__content">
          <div className="pl-modal__form">
            <div className="pl-modal__section-group">
              <div className="pl-modal__section-title">               
              </div> 

              <div className="pl-modal__form-group">
                <div className="label">Title</div>
                <div className="input">                 
                  <input 
                    type="text" 
                    placeholder="Create a short title"
                    value={form.value.title}
                    onChange={(e) => updateInputHandler(e, 'title')}  
                  />
                  <div className="error">{form.error.title}</div>
                </div>
              </div>

              <div className="pl-modal__form-group">
                <div className="label">Incident</div>
                <div className="input">
                  <textarea 
                    placeholder="Describe incident"
                    value={form.value.desc}
                    onChange={(e) => updateInputHandler(e, 'desc')} 
                  >
                  </textarea>
                  <div className="error">{form.error.desc}</div>
                </div>
              </div>

              <div className="pl-modal__form-group">
                <div className="label">Reference link</div>
                <div className="input">                
                  <input 
                    type="text" 
                    placeholder="Link (optional)"
                    value={form.value.refLink}
                    onChange={(e) => updateInputHandler(e, 'refLink')}  
                  />
                  <div className="error">{form.error.refLink}</div>
                </div>
              </div>
            </div>        

          </div>          
          <div className="pl-modal__actions">
            <div className="cancel">
              <button onClick={() => dispatch(toggleLedgerModal(false))}>Cancel</button>
            </div>
            <div className="create">
              <button 
              disabled={form.hasErrors || !form.formCompleted}
              onClick={registerIncidentHandler}
              >
              Create
              </button>
            </div>
          </div>     
        </div>
      </div>
    </Modal>   
  );
}

export default App;
