import './style.scss';
import { Link } from "react-router-dom";

function App({ country, countryShort, list }) {
  return (
    <div className="pl-trendledger">      
      <div className="pl-trendledger__country">
        { country }
      </div>
      <div className="pl-trendledger__ledgers">
        {
          list.map(function(party, i) {               
            return (  
              <Link key={i} to={'ledger/' + countryShort + '/' + party.uniqueTag} className="name">
              @{party.name}
              </Link> 
            )
          })
        }

                 
      </div>
    </div>
  );
}

export default App;
