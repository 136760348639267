import './style.scss';
import * as moment from 'moment';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

function App({ content, deleteIncidentHandler }) {
  const { country, ledgerTag } = useParams();
  const user = useSelector(state => state.user.user);
  const navigate = useNavigate();
  const [isLinkCopied, setLinkCopied] = useState(false);

  function copyLink(e) {
    e.stopPropagation();
    const link = `${window.location.origin}/incident/${country}/${ledgerTag}/${content._id}`;
    navigator.clipboard.writeText(link).then(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
      }, 500);      
    });
  }

  function deleteIncident(e) {
    e.stopPropagation();
    deleteIncidentHandler && deleteIncidentHandler();
  }

  function showDetails(event) {
    if (window.getSelection().toString() === "") {
      event.preventDefault();
      navigate(`/incident/${country}/${ledgerTag}/${content._id}`)
      return;
    }
  }

  function createDescMarkup() {
    return {__html: content.description.replace(/\n/g, '<br>\n')};
  }
 
  return (
    <div className={"pl-node " + (!content && "pl-node-empty")} onClick={showDetails}>
      <div className="pl-node__category-nav">
        <ul className="pl-node__category-ul">
          <li>
           <span className="nav1">{content && content.title }</span>
          </li>                     
        </ul>

        <div className="pl-node__report-info">
          {content &&
            <>
            <span className="reporter">@{content.createrHandler}</span>
            <span className="separator"></span>
            <span className="time">{moment(content.createdAt).fromNow()}</span>
            </>
          }
        </div>
      </div>
     
      <div className="pl-node__incident">
        {content && content.description &&
          <span dangerouslySetInnerHTML={createDescMarkup()}>     
          </span>
        }
        <ul className="pl-node__links">
        {content && content.referenceLink &&     
          <li>
            <a href={content.referenceLink} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>Reference</a>
          </li>
        }          
        </ul>   
      </div>
      <div className="pl-node__meta">
        {content &&
          <ul className="pl-node__meta-actions">
            <li className="copy-link" onClick={copyLink}>
              {!isLinkCopied ?
                <span>copy-link</span>
              :
                <span className="copied">copied</span>
              }
            </li>
            <li className="comments">
             <Link 
              to={`/incident/${country}/${ledgerTag}/${content._id}`} 
              onClick={(e) => e.preventDefault()}>
              comments
              </Link>
            </li>
            {content && user && user.sub === content.createrSub &&
              <li className="delete" onClick={deleteIncident}>delete</li>
            }           
          </ul>
        }
      </div>
    </div>
  );
}

export default App;
