import './style.scss';
import Modal from 'react-modal';
import React, { useState, useRef } from 'react';
import { login, registerUserHandler, toggleLoginModal } from '../../actions/user';
import { useDispatch, useSelector } from "react-redux";

const HANDLER_REGEX = new RegExp(/^([A-Za-z-_0-9])+$/);
const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: "rgb(235 235 235 / 75%)"
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

function App({ children }) {
  const dispatch = useDispatch();
  const isLoginModalOpen = useSelector(state => state.user.isLoginModalOpen);
  const userLogin = useSelector(state => state.user.login);
  const userHandler = useSelector(state => state.user.registerHandler);

  const [handler, setHandlerInput] = useState({value: "", err: ""});

  function updateHandlerInput(e) {
    let err = "";
    if (e.target.value.length < 5) {
      err = "minimum 5 chars, no spaces"
    }

    if (e.target.value.length > 17) {
      err = "max 17 chars, no spaces"
    }

    if (e.target.value.length && !HANDLER_REGEX.test(e.target.value)) {
      err = "only allowed a-z, -, _"
    }

    setHandlerInput({
      value: e.target.value,
      err: err
    });
  }

  function registerUser(e) {
    e.stopPropagation();  
    if (!handler.value) {
      return;
    }

    dispatch(registerUserHandler(userLogin.token, handler.value));
  } 

  const divRef = useRef(null);
  setTimeout(() => {
    /* global google */
    window.google.accounts.id.initialize({
      client_id: window.APPCONFIG.GOOGLE_CLIENT_ID,      
      callback: (resp) => { dispatch(login(resp.credential)) }
    });

    window.google.accounts.id.renderButton(
      divRef.current,
      {
        theme: "outline",
        size: "large"
      }
    );
  }, 0);
 
  return (      
    <Modal
      isOpen={isLoginModalOpen}     
      style={customStyles}      
    >     
      <div className="pl-loginmodal">
        <div className="pl-loginmodal__action">
          <div className="pl-loginmodal__cross" onClick={() => dispatch(toggleLoginModal(false))}>&nbsp;</div>
        </div>      
        <div className="pl-loginmodal__header">
          Sign up/Login
        </div>
        <div className="pl-loginmodal__content">          
          <div className="pl-loginmodal__children"><div ref={divRef}></div> </div>          

          {userLogin.token && userLogin.error === 'USER_NOT_REGISTERED' &&
          <div className="pl-loginmodal__userhandler">
            <div>
              <div className="label">Create User Handler</div>
              <div className="input-wrap">
                <input 
                  type="text" 
                  value={handler.value} 
                  placeholder="Example: jack_sparrow"
                  onChange={updateHandlerInput}
                  onKeyPress={(e) => e.code === 'Enter' && registerUser(e)}
                />
                <button                  
                  disabled={handler.err ? true : false}
                  onClick={registerUser}>          
                  Create
                </button>
              </div>
              <div className="error">{handler.err || userHandler.error}</div>
            </div> 
          </div>
          }        
        </div>
      </div>
    </Modal>   
  );
}

export default App;
