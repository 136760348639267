import './style.scss';
import { Link } from "react-router-dom";

function App({ country, countryFullName, ledgerTag,  ledgerName}) {
  return (
    <div className="pl-ledgertag">
      <Link to={"/ledger/" + country + "/" + ledgerTag} className="pl-ledgertag__hashtag">
        <div className="pl-ledgertag__country">                            
          <div className="name">{countryFullName}</div>
        </div>              
        <div className="pl-ledgertag__category">            
          <div className="name">{ledgerName}</div>
        </div>
      </Link> 
    </div>
  );
}

export default App;