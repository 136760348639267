import './style.scss';
import * as moment from 'moment';
import { Helmet } from 'react-helmet-async';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useEffect, useState } from 'react';

import { getIncident, clearIncident } from '../../actions/incident';
import { listComments } from '../../actions/comment';

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CommentList from "./CommentList";
import LedgerTag from "../../components/LedgerTag";
import WriteComment from '../../components/WriteComment';

import { getFullName } from '../../../ledgers/countries';
import { getName } from '../../../ledgers/political-parties';

import * as publicAPI from '../../api/public';
import * as commentAPI from '../../api/comment';

function App() {
  const lastPage = useRef({
    skip: 0,
    limit: 10,
    isPending: false
  });

  const dispatch = useDispatch();
  const { country, ledgerTag, incidentId } = useParams();
  const incident = useSelector(state => state.incident.get); 
  const [commentList, setCommentsList] = useState([]);

  useEffect(() => {   
    dispatch(getIncident(incidentId));
    publicAPI.listComments(lastPage.current.skip, lastPage.current.limit, incidentId).then((resp) => {
      !resp.error && setCommentsList(resp.data);     
    });

    return () => {  
      dispatch(clearIncident());
    };   
  }, []); 

  function addComment(inputValue, cb) {
    commentAPI.addComment(incidentId, {
      parentId: "",
      comment: inputValue
    }).then((resp) => {
      if (resp.error)  {
        return;
      }

      setCommentsList([
        resp.data,
        ...commentList           
      ]);

      cb();      
    });
  }

  function loadMoreComments() {
    lastPage.current = {
      ...lastPage.current,
      skip: lastPage.current.skip + lastPage.current.limit,
      isPending: true
    }; 

    publicAPI.listComments(lastPage.current.skip, lastPage.current.limit, incidentId)
    .then((resp) => {
      if (!resp.error) {
        setCommentsList([         
          ...commentList,
          ...resp.data,
        ]);
      }    
    });   
  }

  return (
    <div className="pl-offense">      
      <Helmet>        
       {incident.data &&
        <>
        <meta property="og:title" content={incident.data.title} />
        <meta property="og:description" content={incident.data.description} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={incident.data.title} />
        <meta name="twitter:description" content={incident.data.description} />         
        </>
        }                       
      </Helmet>

      <Header mode="standard-inverse" />
      <section className="pl-offense__content">
        <div className="pl-offense__content-inner">
          <div className="pl-offense__action-desk">
            <LedgerTag             
              country={country}
              countryFullName={getFullName(country)}
              ledgerTag={ledgerTag}
              ledgerName={getName(country, ledgerTag)} 
            />     
          </div>

          <div className="pl-offense__ledger-list">            
            {incident.data &&
              <div className="pl-offense__details">
              <div className="pl-offense__details-container">
                <div className="pl-offense__details-category-nav">
                  <ul className="pl-offense__details-category-ul">
                    <li>
                     <span className="nav1">{incident.data.title}</span>
                    </li>            
                  </ul>

                  <div className="pl-offense__details-report-info">
                    <span className="reporter">@{incident.data.createrHandler}</span>
                    <span className="separator"></span>
                    <span className="time">{moment(incident.data.createdAt).fromNow()}</span>
                  </div>
                </div>
               
                <div className="pl-offense__details-incident">
                  {incident.data.description}
                  <ul className="pl-offense__details-links">
                    {incident.data.referenceLink &&     
                      <li>
                      <a href={incident.data.referenceLink} target="_blank" rel="noreferrer">Reference</a>
                      </li>
                    }           
                  </ul>
                </div>
                <div className="pl-offense__details-meta">
                  <ul className="pl-offense__details-meta-actions">                 
                  </ul>
                </div>       
              </div>
              <div className="pl-offense__details-nodecomments">
                <WriteComment addCommentHandler={addComment} />
                <CommentList incidentId={incidentId} commentList={commentList} />
                {commentList.length > 0 && 
                  <div className="pl-offense__details-comment-loadmore" onClick={loadMoreComments}>
                    Load more
                  </div>
                }
              </div>      
              </div>
            }
          </div>
        </div>
      </section>         
      <Footer />          
    </div>
  );
}

export default App;

