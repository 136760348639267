import { makeRequest } from '../utils/axios';

export const getIncident = async (incidentId) => makeRequest({
  url: `/api/public/incident/${incidentId}`,
  method: 'GET'
});

export const listIncidents = async (skip, limit, country, ledgerTag) => makeRequest({
  url: `/api/public/incident/${country}/${ledgerTag}?skip=${skip}&limit=${limit}`,
  method: 'GET'
});

export const listComments = async (skip, limit, incidentId) => makeRequest({
  url: `/api/public/comment/${incidentId}?skip=${skip}&limit=${limit}`,
  method: 'GET'
});

export const getChildCommentList = async (skip, limit, parentId) => makeRequest({
  url: `/api/public/comment/parent/${parentId}?skip=${skip}&limit=${limit}`,
  method: 'GET'
});
