import * as userAPI from '../api/user';

export const toggleLoginModal = (isLoginModalOpen) => async (dispatch) => {
  dispatch({
    type: "TOGGLE_LOGIN_MODAL",
    isLoginModalOpen: isLoginModalOpen   
  });
}

export const login = (idToken) => async (dispatch) => {  
  dispatch({
    type: "USER_LOGIN",
    value: await userAPI.login(idToken),
    token: idToken
  });
}

export const registerUserHandler = (idToken, handler) => async (dispatch) => {
  dispatch({
    type: "REGISTER_USER_HANDLER",
    value: await userAPI.registerUserHandler(idToken, handler),
    token: idToken    
  });
}

export const logout = () => async (dispatch) => {
  dispatch({
    type: "USER_LOGOUT"    
  });
}

