import './style.scss';
import { debounce } from 'lodash';
import { Helmet } from 'react-helmet-async';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useEffect, useState } from 'react';

import { 
  toggleLedgerModal, 
  toggleListPending, 
  deleteIncident, 
  listIncidents,
  clearIncidents 
} from '../../actions/incident';
import { toggleLoginModal } from '../../actions/user';

import Node from "../../components/Node";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LedgerTag from "../../components/LedgerTag";
import ConfirmModal from "../../components/ConfirmModal";
import NewIncidentModal from "../../components/NewIncidentModal";

import { getFullName } from '../../../ledgers/countries';
import { getName } from '../../../ledgers/political-parties';

function App() { 
  const lastPage = useRef({
    skip: 0,
    limit: 30,
    isPending: false
  });

  const { country, ledgerTag } = useParams();
  const dispatch = useDispatch();
  const [incidentForDelete, setIncidentForDelete] = useState(null);
  const user = useSelector(state => state.user.user);  
  const ledgerList = useSelector(state => state.incident.list);

  useEffect(() => {   
    dispatch(listIncidents(lastPage.current.skip, lastPage.current.limit, country, ledgerTag));
    return () => {  
      dispatch(clearIncidents());
    }; 
  }, []);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target.scrollingElement;
    const contentHeight = scrollHeight - clientHeight;    

    if ((scrollTop + 10) >= contentHeight && !lastPage.current.isPending) {
      lastPage.current = {
        ...lastPage.current,
        skip: lastPage.current.skip + lastPage.current.limit,
        isPending: true
      };   
      
      dispatch(toggleListPending(true));
      dispatch(listIncidents(
        lastPage.current.skip, 
        lastPage.current.limit, 
        country, ledgerTag
      )).then((resp) => {      
        lastPage.current = {
          ...lastPage.current,         
          isPending: false
        };
      });
    } 
  };

  useEffect(() => {
    document.addEventListener('scroll', debounce(handleScroll, 200));
    return () => {  
      document.removeEventListener('scroll', handleScroll);
    };   
  }, []);

  function registerNewOffense() {
    if (!user) {
      dispatch(toggleLoginModal(true));
      return;
    }

    dispatch(toggleLedgerModal(true));
  }

  function confirmDeleteIncident() {
    dispatch(deleteIncident(incidentForDelete._id));
    setIncidentForDelete(null);
  }

  const metaDesc = `A Ledger for documenting Offenses of ${getFullName(country)} "/" ${getName(country, ledgerTag)}`;

  return (
    <div className="pl-ledger">
      <Helmet>
        <meta property="og:title" content="Political Ledger" />
        <meta property="og:description" content={metaDesc} />
        <meta property="og:url" content={window.location.href} />

        <meta name="twitter:title" content="Political Ledger" />
        <meta name="twitter:description" content={metaDesc} />               
      </Helmet>

      <Header mode="standard-inverse" />
      <section className="pl-ledger__content">
        <div className="pl-ledger__content-inner">
          <div className="pl-ledger__action-desk">

            <div className="pl-ledger__hashtag">
              <LedgerTag             
                country={country}
                countryFullName={getFullName(country)}
                ledgerTag={ledgerTag}
                ledgerName={getName(country, ledgerTag)} 
              />
            </div>
            <div className="pl-ledger__new-offense">
              <button onClick={registerNewOffense}>
              Add new incident
              </button>
            </div>     
          </div>

          <div className="pl-ledger__ledger-list"> 
          {
            ledgerList.data.map(function(content, i) {                
              return (
                <Node 
                  key={i} 
                  content={content} 
                  deleteIncidentHandler={() => setIncidentForDelete(content)}
                />               
              )
            })
          }

          {ledgerList.firstTimeLoad &&
            [1, 2, 3].map(function(content, i) {                
              return (                
                <Node key={i} content={null} />
              )
            })
          }
          </div> 
          <div className="pl-ledger__ledger-list-status">
            {!ledgerList.firstTimeLoad && !ledgerList.data.length &&
              <div className="empty">
                No records
              </div>
            }                      
            <div className="loading">
              <span>
              &nbsp;{ledgerList.isPending && <i className="fa fa-circle-o-notch fa-spin"></i>}
              </span>                
            </div>           
          </div>     
        </div>
      </section>         
      <Footer />
      <NewIncidentModal />
      <ConfirmModal
        isOpen={!!incidentForDelete}
        title={"Confirm Delete"}
        desc={"Are you sure you want to delete this incident?"}
        btnLabel={"Delete"}
        cancelHandler={() => setIncidentForDelete(null)}
        confirmHandler={confirmDeleteIncident}
      />         
    </div>
  );
}

export default App;

