import { makeRequest } from '../utils/axios';

export const login = async idToken => makeRequest({
  url: '/api/user/tokensignin',
  method: 'GET',
  headers: {
    Inception: idToken
  }
});

export const registerUserHandler = async (idToken, handler) => makeRequest({
  url: `/api/user/userhandler/${handler}`,
  method: 'GET',
  headers: {
    Inception: idToken
  }
});
