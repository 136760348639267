import './style.scss';

import Footer from "../../components/Footer";
import Ledger from "../../components/Ledger";

import { getFullName } from '../../../ledgers/countries';
import pparties from '../../../ledgers/political-parties';

function App() {
  return (
    <div className="pl-cluster">      
      <section className="pl-cluster__content">
        <div className="pl-cluster__content-inner">
          <div className="pl-cluster__heading">
            <div>Select a <span>political ledger</span> below to begin</div>
          </div> 
          <div className="pl-cluster__trending_ledger">
            <div className="list-wrap">                        
              {
                Object.keys(pparties).map(function(cShort, i) {                
                  return (                   
                    <div className="list" key={i}>
                      <Ledger 
                        countryShort={cShort}
                        country={getFullName(cShort)} 
                        list={pparties[cShort]} 
                      />
                    </div>
                  )
                })
              }

            </div>
          </div>         
        </div>
      </section>         
      <Footer />        
    </div>
  );
}

export default App;

