import { makeRequest } from '../utils/axios';

export const addComment = async (incidentId, payload) => makeRequest({
  url: `/api/comment/${incidentId}`,
  method: 'POST',
  data: {
    ...payload
  }
});

export const deleteComment = async (commentId) => makeRequest({
  url: `/api/comment/${commentId}`,
  method: 'DELETE'
});
