import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { createBrowserHistory } from "history";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Ledger from "./pages/ledger";
import Incident from "./pages/incident";
import IncidentDetails from "./pages/incident-details";

import store from './store';
const customHistory = createBrowserHistory();
const helmetContext = {};

export default function RouteApp() {
  return (
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <BrowserRouter history={customHistory}>  
          <Routes >
            <Route path='/' element={<Ledger/>} />
            <Route path='/ledger/:country/:ledgerTag' element={<Incident/>} />
            <Route path='/incident/:country/:ledgerTag/:incidentId' element={<IncidentDetails/>} />
          </Routes>
        </BrowserRouter>                    
      </Provider>
    </HelmetProvider>   
  );
}


