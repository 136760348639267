const initialState = {
  isLedgerModalOpen: false,
  list: {
    firstTimeLoad: true,
    isPending: false,   
    data: [],
    error: ''
  },
  get: {
    isPending: false,    
    data: null,
    error: ''
  }
}

export default function incident(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_LEDGER_MODAL":
      return {       
        ...state,
        isLedgerModalOpen: action.isLedgerModalOpen 
      }

    case "CLEAR_INCIDENT":
      return {       
        ...state,
          get: {
            ...state.get,
            data: null,
            isPending: false,        
            error: ""
          }     
      }

    case "CLEAR_INCIDENTS":
      return {       
          ...state,
          list: {
            ...state.list,
            firstTimeLoad: true,
            data: [],
            isPending: false,        
            error: ""
          }      
      }

    case "GET_INCIDENT":
      return {       
        ...state,
        get: {       
          data: action.value.data,
          error: action.value.error
        }      
      }

    case "DELETE_INCIDENT":
      if (!action.value.data.deletedCount) {
        return {       
          ...state,
          list: {
            ...state.list,        
            error: "failed to delete"
          }      
        }
      }

      const deleteIndex = state.list.data.findIndex((i) => i._id === action.incidentId);
      
      return {       
        ...state,
        list: {
          ...state.list,      
          data: [
            ...state.list.data.slice(0, deleteIndex),
            ...state.list.data.slice(deleteIndex + 1)
          ],
          error: action.value.error
        }      
      }

    case "TOGGLE_IS_LIST_PENDING":
      return {       
        ...state,
        list: {
          ...state.list,
          isPending: action.isPending
        }
      }

    case "LIST_INCIDENTS": 
      const newIncidens = (!action.value.error && action.value.data) ? action.value.data : [];

      return {       
      	...state,
        list: {
          ...state.list,    
          data: [
            ...state.list.data,
            ...newIncidens
          ],
          firstTimeLoad: false,
          isPending: false,
          error: action.value.error                
        }      
      }

    case "REGISTER_INCIDENT":
      let newList = [...state.list.data];     
      if (!action.value.error && action.value.data) {
        newList = [
          action.value.data,
          ...state.list.data
        ];
      }

      return {       
        ...state,
        isLedgerModalOpen: !action.value.error ? false : state.isLedgerModalOpen,
        list: {
          error: action.value.error,
          data: newList          
        }      
      }
 
    default:
      return state
  }
}