import './style.scss';
import React, { useRef, useState } from 'react';
import { useSelector } from "react-redux";

import Comment from '../../../components/Comment';

import * as publicAPI from '../../../api/public';
import * as commentAPI from '../../../api/comment';

function App({ incidentId, commentList }) {
  const lastPage = useRef({
    skip: 0,
    limit: 2,
    isPending: false
  });

  const [childComments, setchildCommentList] = useState({});
  const user = useSelector(state => state.user.user); 

  function viewReplies(comment) {
    publicAPI.getChildCommentList(lastPage.current.skip, lastPage.current.limit, comment._id).then((resp) => {     
      if(!resp.error) {       
        setchildCommentList({
          ...childComments,
          [comment._id]: resp.data
        });

        lastPage.current = {
          ...lastPage.current,
          skip: lastPage.current.skip + lastPage.current.limit,
          isPending: true
        };
      }  
    });
  }

  function addCommentHandler(comment, inputValue, cb) {
    commentAPI.addComment(incidentId, {
      parentId: comment._id,
      comment: inputValue
    }).then((resp) => {
      if (resp.error)  {
        return;
      }

      const oldComments = childComments[comment._id] ? childComments[comment._id] : [];
      setchildCommentList({
        ...childComments,
        [comment._id]: [        
          resp.data,
          ...oldComments
        ]
      });

      cb();      
    });
  }

  function deleteComment(comment, cb) {
    commentAPI.deleteComment(comment._id).then((resp) => {
      if (resp.error)  {
        return;
      }

      cb();      
    });
  }

  return (
    <div className="pl-nodecomments">     
      <div className="pl-nodecomments__comment-list">
        {
          commentList.map(function(comment, i) {                
            return (
              <div key={i}>           
                <Comment
                  ownerInSession={user && user.sub === comment.userSub}                
                  comment={comment}
                  viewRepliesHandler={() => {viewReplies(comment)}}
                  addCommentHandler={(inputValue, cb) => {addCommentHandler(comment, inputValue, cb)}}
                  deleteCommentHandler={(cb) => {deleteComment(comment, cb)}}                           
                />

                <div className="pl-nodecomments__reply-wrap">
                  {comment && childComments[comment._id] &&
                    <App incidentId={incidentId} commentList={childComments[comment._id]} />
                  }
                </div>                
              </div>           
            )
          })
        }           
      </div>      
    </div>     
  );
}

export default App;
