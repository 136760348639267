import './style.scss';
import * as moment from 'moment';
import React, { useState } from 'react';
import WriteComment from '../WriteComment';

function App({
  ownerInSession, 
  comment, 
  addCommentHandler, 
  viewRepliesHandler, 
  deleteCommentHandler 
}) {
  const [enableReply, toggleReply] = useState(false);
  const [isDeleted, toggleDeleteLabel] = useState(false);  

  function addComment(inputValue, cb) {
    addCommentHandler(inputValue, () => {
      toggleReply(false);
      cb();
    });    
  }

  function deleteComment() {
    deleteCommentHandler(() => {
      toggleDeleteLabel(true); 
    });
  }

  return (
    <div className="pl-commentscom">
      <div className="comment-wrap">
        {(!isDeleted && !comment.isDeleted) && 
          <div className="handler-info">                          
            <span className="reporter">@{comment.userHandler}</span>
            <span className="separator"></span>
            <span className="time">{moment(comment.updatedAt).fromNow()}</span>          
          </div>
        }
        <div className="comment-itself">
          {(!isDeleted && !comment.isDeleted) ?
          <span className="active">{comment.comment}</span>
          :
          <span className="deleted">User has deleted</span>
          }
        </div>
      </div>

      <div className="action-desk">
        <div className="reply" onClick={() => toggleReply(true)}>Reply</div>
        <div className="view-reply" onClick={viewRepliesHandler}>
          View replies 
          <span style={{'fontSize': '11px'}}>&nbsp;{comment.numberOfReplies}</span>
        </div>
        {(!isDeleted && !comment.isDeleted && ownerInSession) &&
          <div className="delete-comment" onClick={deleteComment}>Delete</div>
        }
      </div>
     
      {enableReply &&
        <div className="write-reply">
          <WriteComment addCommentHandler={addComment} />
        </div>
      }                     
    </div>
  );
}

export default App;
