import * as publicAPI from '../api/public';
import * as incidentAPI from '../api/incident';

export const toggleLedgerModal = (isLedgerModalOpen) => async (dispatch) => {
  dispatch({
    type: "TOGGLE_LEDGER_MODAL",
    isLedgerModalOpen: isLedgerModalOpen   
  });
};

export const clearIncident = () => async (dispatch) => {
  dispatch({
    type: "CLEAR_INCIDENT"    
  });
};

export const clearIncidents = () => async (dispatch) => {
  dispatch({
    type: "CLEAR_INCIDENTS"    
  });
};

export const toggleListPending = (isPending) => async (dispatch) => {
  dispatch({
    type: "TOGGLE_IS_LIST_PENDING",
    isPending: isPending   
  });
};

export const registerIncident = (payload) => async (dispatch) => {
  const resp = await incidentAPI.registerIncident(payload) 
  dispatch({
    type: "REGISTER_INCIDENT",
    value: resp 
  });

  return Promise.resolve(resp);  
};

export const getIncident = (incidentId) => async (dispatch) => {
  dispatch({
    type: "GET_INCIDENT",
    value: await publicAPI.getIncident(incidentId)  
  });  
};

export const deleteIncident = (incidentId) => async (dispatch) => {
  dispatch({
    type: "DELETE_INCIDENT",
    incidentId: incidentId,
    value: await incidentAPI.deleteIncident(incidentId)  
  });  
};

export const listIncidents = (skip, limit, country, ledgerTag) => async (dispatch) => {
  const resp = await publicAPI.listIncidents(skip, limit, country, ledgerTag);
  dispatch({
    type: "LIST_INCIDENTS",
    value: resp
  });

  return Promise.resolve(resp); 
};
