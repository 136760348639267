import { makeRequest } from '../utils/axios';

export const registerIncident = async (payload) => makeRequest({
  url: '/api/incident',
  method: 'POST',
  data: {
    ...payload
  }
});

export const deleteIncident = async (incidentId) => makeRequest({
  url: `/api/incident/${incidentId}`,
  method: 'DELETE'
});
