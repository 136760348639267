import './style.scss';
import { Link } from "react-router-dom";
import { toggleLoginModal, logout } from '../../actions/user';
import LoginModal from "../LoginModal";
import { useDispatch, useSelector } from "react-redux";

function App({ mode = "" }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const userHandler = useSelector(state => state.user.userHandler);
  
  return (
    <header className={"pl-header" + " " + mode}>
      <div className="pl-header__inner">
        <div className="pl-header__wrap">       
          <div className="pl-header__title">
            <Link to="/">           
              <span className="title-parts1">
                political
              </span>
              <span className="title-parts2">
                Ledger
              </span>
            </Link>       
          </div>
          <div className="pl-header__actions">
            {!user &&           
              <div className="pl-header__user-login" onClick={() => dispatch(toggleLoginModal(true))}>Login</div>            
            }
            {user &&
              <>
                <div className="pl-header__user">{user ? "@" + userHandler : ""}</div>
                <ul className="pl-header__user-dashboard">            
                  <li onClick={() => dispatch(logout())}>Logout</li>                          
                </ul>
              </>
            }          
          </div>
        </div>            
      </div>
      <LoginModal />            
    </header>
  );
}

export default App;


