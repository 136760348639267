import './style.scss';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: "rgb(235 235 235 / 75%)"
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

function App({
    isOpen,
    title,
    desc,
    btnLabel,
    cancelHandler,
    confirmHandler
}) {
  return (      
    <Modal
      isOpen={isOpen}     
      style={customStyles}      
    >     
      <div className="pl-confirmmodal">       
        <div className="pl-confirmmodal__header">
          <div className="header-incident">
            {title}
          </div>
        </div>
        <div className="pl-confirmmodal__content">          
          <div className="pl-confirmmodal__desc">{desc}</div>

          <div className="pl-modal__actions">
            <div className="cancel">
              <button onClick={cancelHandler}>Cancel</button>
            </div>
            <div className="create">
              <button onClick={confirmHandler}>
              {btnLabel}
              </button>
            </div>
          </div>    
        </div>
      </div>
    </Modal>   
  );
}

export default App;
