import thunk from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { routerReducer } from 'react-router-redux';

import user from './reducers/user';
import incident from './reducers/incident';
import comment from './reducers/comment';

const rootReducer = combineReducers({
   user,
   incident,
   comment,
   routing: routerReducer
});

export default createStore(
   rootReducer,
   applyMiddleware(thunk)
);
